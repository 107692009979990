import Constants from "../../actionTypes";

const initialState = {
    test: {}
};

export const test = (state = initialState, action) => {
    switch (action.type) {
        case Constants.SET_TEST:
            return {
                ...state,
                test: action.payload
            };
        default:
            return state;
    }
}
