import React, { Component } from 'react';
import { startLoading, stopLoading } from "../components/Util";

class ErrorBoundary extends Component {
    constructor(props) {
        super();
        this.state = { loaded: true, error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        !this.state.loaded ? startLoading() : stopLoading();
        if (this.state.errorInfo) {
            console.error("ERROR:", this.state.errorInfo.componentStack);
            return <div className="popup">
                <div className="modal" style={{ display: "flex" }}>
                    <div className="modal-card" style={{ width: '400px' }}>
                        <header className="modal-card-head">
                            <p className="modal-card-title"><i className="mdi mdi-24px mdi-information" style={{ color: '#d26b10' }}></i> Alert</p>
                        </header>
                        <section className="modal-card-body">
                            Your request could not be completed at this time. Please try again later.
                        </section>
                        <footer className="modal-card-foot is-justify-content-right">
                            <button className="button is-primary btn-standard" style={{ minWidth: "100px" }} type="button" onClick={e => {
                                localStorage.clear();
                                window.location.reload(false);
                            }}>OK</button>
                        </footer>
                    </div>
                </div>
            </div>
            // return (
            //     React.createElement("div", { style: { color: "white", textAlign: "center", marginTop: "5%" } },
            //         React.createElement("h1", { style: { fontSize: '25px' } }, "Something went wrong."),
            //         React.createElement("details", { style: { whiteSpace: 'pre-wrap' } },
            //             this.state.error && this.state.error.toString(),
            //             React.createElement("br", null),
            //             this.state.errorInfo.componentStack))
            // );
        }
        return this.props.children;
    }
}

export default (ErrorBoundary);