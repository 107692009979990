import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const inititalState = {};

export const reduxStore = createStore(
  rootReducer,
  inititalState,
  compose(applyMiddleware(thunk))
);