import React, { Component } from 'react';
import { startLoading } from "./components/Util";

class Loading extends Component {
    render() {
        startLoading();
        return <></>
    }
}

export default Loading;