import { get, post } from "./Service";

export const getToken = (authId, onDone) => {
    get(`/api/security/token/${authId}`, token_response => {
        let token = token_response && token_response.data;
        onDone(token);
    });
};

export const getInstrospect = onDone => {
    get("/api/security/introspect", res => {
        let me = res && res.data;
        onDone(me);
    });
};

export const getAnonymousUser = (userName, onDone) => {
    get("/api/security/anonymous/" + userName, user => {
        onDone(user);
    });
};

export const validatePreviouslyUsed = (payload, onDone) => {
    post("/api/security/check-repeat-password", payload, (res) => {
        onDone(res.data);
    });
};

export const logout = (onDone) => {
    get("/api/security/logout", res => {
        onDone(res);
    });
};

export const sessionTimeOut = (onDone) => {
    get("/api/security/session-time-out", res => {
        onDone(res);
    });
};

export const getProduct = (onDone) => {
    get("/api/security/get-product", res => {
        onDone(res && res.data ? res.data : 'LIMS-ELN');
    });
};